<template>
  <div>
    <SuspenseSlotWrapper>
      <Suspense>
        <ContentPageWrapper :route="$route.path" />
        <template #fallback>
          <div style="height: 75vh" class="relative p-0 m-0 z-[509]">
            <LoaderPageContent />
          </div>
        </template>
      </Suspense>
    </SuspenseSlotWrapper>
  </div>
</template>
<script setup lang="ts">
import LoaderPageContent from '~~/src/components/loader/pageContent/vdv/page-content.vue';
import ContentPageWrapper from '~~/src/components/contentPages/contentPageWrapper/vdv/contentPageWrapper.vue';
import { useAreaStore } from '@/stores/useArea';
import SuspenseSlotWrapper from '~~/src/components/suspenseSlotWrapper.vue';

onMounted(async () => {
  if (import.meta.client) {
    window.scrollTo(0, 0);
  }
});

const areaStore = useAreaStore();

useHead({
  link: [
    {
      rel: 'canonical',
      href: import.meta.server
        ? 'https://' +
          useRequestHeaders(['host']).host +
          useRouter().currentRoute?.value?.path
        : window.location.origin + useRouter().currentRoute?.value?.path,
    },
  ],
  style: [
    {
      id: 'areaStyles',
      innerHTML: `:root {${areaStore.areaStyles};${areaStore.activeAreaStyles}}`,
    },
  ],
});
</script>
