<template>
  <div v-if="pageData" data-testid="blockParent">
    <SeoGenerator :page-entry="pageData" />
    <ContentPage :page-entry="pageData" />
  </div>
</template>
<script setup lang="ts">
import useLoadCMSPage from '@/composables/useLoadCMSPage';
import SeoGenerator from '@/components/meta/seoGenerator.vue';
import ContentPage from '@/components/contentPages/contentPage/vdv/ContentPage.vue';
import { PageTypeHandle } from '~/@types/type-handle';
import type { EventPage } from '~/server/transformers/page/eventPage';

const props = defineProps({
  route: {
    type: String,
    required: true,
  },
});

const { pageData, redirect, error } = await useLoadCMSPage(props.route);
if (error.value === true) await navigateTo('/404');
else if (redirect) await navigateTo(...redirect);

onMounted(async () => {
  if (import.meta.client) {
    window.scrollTo(0, 0);
  }
});

if (pageData.value?.typeHandle === PageTypeHandle.EVENT) {
  if ((pageData.value as EventPage).event?.entryIsHidden) {
    useHead({
      meta: [
        {
          name: 'robots',
          content: 'noindex, noarchive',
        },
      ],
    });
  }
}
</script>
