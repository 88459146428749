<template>
  <SubNav
    v-if="
      pageEntry.typeHandle !== PageTypeHandle.PAGE &&
      pageEntry.typeHandle !== PageTypeHandle.LANDINGPAGE
    "
    :page-data="pageEntry as BaseSubPageData"
  />
  <PageContent :page-content-entries="pageEntry.pageContent" :is-root="true" />
</template>

<script setup lang="ts">
import SubNav from '~~/src/components/components/views/subNav/SubNav.vue';
import PageContent from '~~/src/components/pageContent/vdv/PageContent.vue';
import { PageTypeHandle } from '~~/src/@types/type-handle';
import type { BasePageData } from '~~/src/server/transformers/page/basePage';
import type { BaseSubPageData } from '~~/src/server/transformers/page/subPage';
import { UseStateKeys } from '~/useStateKeys';

const props = defineProps({
  pageEntry: {
    type: Object as PropType<BasePageData>,
    required: true,
  },
});

useState<BasePageData>(UseStateKeys.CMS_PAGE_DATA, () => props.pageEntry);
</script>
